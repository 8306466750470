<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <div>
                  <div class="logo-wrapper">
                    <img
                      src="@/assets/images/logo/logo.png"
                      width="200px"
                      height="auto"
                    >
                    <h3 class="text-primary invoice-logo" />
                  </div>
                  <b-card-text class="mb-0">
                    RUE EL KOUNAITRA 1-dar Chaabene El Fehri
                  </b-card-text>
                  <b-card-text class="mb-0">
                    gsr-cle1@hotmail.fr
                  </b-card-text>
                  <b-card-text class="mb-0">
                    +216 97 607 740
                  </b-card-text>
                  <b-card-text class="mb-0">
                    MF : 1082793F/A/M/000
                  </b-card-text>

                </div>

                <div class="invoice-number-datedate mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Date
                    </h4>
                    <flat-pickr
                      v-model="invoiceData.date"
                      class="form-control invoice-edit-input"
                    />
                  </div>
                  <div>
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="has_transportation_fee"
                      name="checkbox-1"
                    >
                      Facture comporte des frais de livraison de transport
                    </b-form-checkbox>
                    <b-col
                      v-if="has_transportation_fee"
                      cols="12"
                      lg="12"
                    >
                      <label class="d-inline d-lg-none">Frais de livraison</label>
                      <b-form-input
                        v-model="transportation_fee_amount"
                        value="7"
                        type="number"
                        class="mb-2"
                        placeholder="montant du frai de livraison"
                      />
                    </b-col>
                  </div>
                </div>
              </div>
            </b-card-body>
            <hr class="invoice-spacing">
            <b-card-body
              class="invoice-padding pt-0"
            >
              <b-row>

                <b-col
                  cols="12"
                  xl="6"

                  class="mb-lg-1"
                >
                  <h6 class="mb-1">
                    Client
                  </h6>
                  <v-select
                    v-model="invoiceData.customer"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="customers"
                    label="company_name"
                    input-id="invoice-data-client"
                    :clearable="false"
                    placeholder="Choisir un fournisseur"
                  />

                  <!-- Selected Client -->
                  <div
                    v-if="invoiceData.customer"
                    class="mt-1"
                  >
                    <h6
                      class="mb-25"
                    >
                      Client : {{ invoiceData.customer.company_name }}
                    </h6>
                  </div>
                </b-col>
                <feather-icon
                  size="16"
                  icon="XOctagonIcon"
                  class="cursor-pointer mt-4"
                  @click="invoiceData.customer=null"
                />
                <!-- tags -->
                <b-col
                  cols="12"
                  xl="6"

                  class="mb-lg-1"
                >
                  <h6 class="mb-1">
                    Filters
                  </h6>
                  <v-select
                    v-model="selectedTags"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tags"
                    label="name"
                    input-id="invoice-data-client"
                    :clearable="true"
                    :reduce="tag => tag.id"
                    multiple
                    required
                  />
                </b-col>
                <b-col
                  cols="12"
                  xl="6"

                  class="mb-lg-1"
                >
                  <h6 class="mb-1">
                    TVA
                  </h6>
                  <v-select
                    v-model="selectedVat"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="vats"
                    label="value"
                    input-id="invoice-data-client"
                    :clearable="true"
                    :reduce="vat => vat.id"
                    required
                  />
                </b-col>
              </b-row>
            </b-card-body>

            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{height: trHeight}"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <b-col cols="12">

                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Quantité
                        </b-col>
                        <b-col
                          cols="12"
                          lg="5"
                        >
                          Labelle
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Prix ttc
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Prix total
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Qty</label>
                          <b-form-input
                            v-model="item.qty"
                            value="1"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="5"
                        >
                          <label class="d-inline d-lg-none">Labelle</label>
                          <b-form-input
                            v-if="item.product.name == ''"
                            v-model="item.product.name"
                            class="mb-2"
                          />
                          <v-select
                            v-else
                            v-model="item.product"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="products"
                            label="name"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            @input="setSelected(item,index)"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Prix</label>
                          <b-form-input
                            v-model="item.product.sales_price_ttc"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Prix</label>
                          <p
                            v-if="item.product.sales_price_ttc"
                            class="mb-1"
                          >
                            {{ (item.product.sales_price_ttc * item.qty).toFixed(3) }}
                          </p>

                        </b-col>

                      </b-row>
                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />
                      </div>
                    </div></b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItem"
              >
                Ajouter produit
              </b-button>
            </b-card-body>

            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                />

                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Timbre fiscal:
                      </p>
                      <p class="invoice-total-amount">
                        1 DT
                      </p>
                    </div>
                    <hr class="my-50">
                    <div
                      v-if="totalTTC"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Prix T.T.C:
                      </p>
                      <p class="invoice-total-amount">
                        {{ Number(totalTTC)+1 }} DT
                      </p>
                    </div>
                    <div
                      v-else
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Prix T.T.C:
                      </p>
                      <p class="invoice-total-amount">
                        0
                      </p>
                    </div>
                  </div></b-col></b-row>
            </b-card-body>

            <hr class="invoice-spacing">

          </b-card>
        </b-form>
      </b-col>

      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >

        <b-card>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            :class="{ 'disabled-cursor': isLoading || !isInvoiceValid }"
            :disabled="isLoading || !isInvoiceValid"
            @click="save"
          >
            Enregistrer
          </b-button>
        </b-card>

      </b-col>
    </b-row>
    <invoice-sidebar-add-new-customer />

  </section>

</template>

<script>
import { ref } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormInput, BInputGroup, BInputGroupPrepend, VBToggle, BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import { mapGetters, mapActions } from 'vuex'
import InvoiceSidebarAddNewCustomer from './CreateCustomer.vue'

export default {
  name: 'CreateInvoice',
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckboxGroup,
    BFormCheckbox,
    flatPickr,
    InvoiceSidebarAddNewCustomer,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },

  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  data() {
    return {
      last: [],
      customers: [],
      tags: [],
      selectedTags: null,
      selectedVat: null,
      barcode: '',
      exist: false,
      has_transportation_fee: false,
      transportation_fee_amount: null,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('vat', ['vats']),
    totalTTC() {
      return this.invoiceData.items.reduce(
        (accumulator, item) => accumulator + item.product.sales_price_ttc * item.qty,
        0,
      )
    },
    isInvoiceValid() {
      return (
        this.selectedVat !== null
        && this.selectedTags !== null
        && this.invoiceData.customer !== null
      )
    },
  },

  mounted() {
    this.initTrHeight()
  },
  created() {
    this.getCustomers()
    this.getTags()
    window.addEventListener('resize', this.initTrHeight)
    this.getVats()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    ...mapActions('vat', ['getVats']),
    async getCustomers() {
      this.isLoading = true
      const { data } = await axiosIns.get('/customers/')
      this.customers = data
      this.isLoading = false
    },
    async getTags() {
      const { data } = await axiosIns.get('/tags/')
      this.tags = data
    },
    setSelected(value, index) {
      const f = this.invoiceData.items.filter(item => item.product === value.product)
      if (f.length > 1) {
        this.withTitle(index, f, value.qty)
      }
    },
    async refresh() {
      this.exist = false
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)

      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')}`
    },
    withTitle(index, f, qty) {
      this.$swal({
        title: 'Produit déja ajouté au facture',
        text: `Voulez vous vraiment ajouter ${qty} au quantité déja existe`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.removeItem(index)
          // eslint-disable-next-line operator-assignment
          f[0].qty = Number(f[0].qty) + Number(qty)
          this.$swal({
            icon: 'success',
            title: 'Quantité augmenté!',
            text: `Quantité augmenté + ${qty}!`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else {
          this.removeItem(index)
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    async save() {
      const date = new Date()
      const now = this.formatFn(date.toISOString())
      let customer
      if (this.invoiceData.customer) {
        customer = this.invoiceData.customer.id
      } else {
        customer = null
      }

      const obj = {
        date: this.invoiceData.date,
        customer,
        products: this.invoiceData.items,
        date_today: now,
        has_transportation_fee: this.has_transportation_fee,
      }

      if (this.transportation_fee_amount !== null) {
        obj.transportation_fee_amount = this.transportation_fee_amount
      }

      if (this.selectedTags !== null) {
        obj.tags = this.selectedTags
      }

      if (this.selectedVat !== null) {
        obj.vat = this.selectedVat
      }
      try {
        await axiosIns.post('/invoices/create/', obj)
        this.$router.push({ name: 'invoices' })
        this.showToast('success', 'top-center', 'Facture ajouté avec succés')
      } catch (error) {
        this.showToast('danger', 'top-center', error.toString())
      }
    },
    async addNewItem(result) {
      const itemFormBlankItem = {
        product: result,
        qty: 1,
      }
      this.invoiceData.items.push(JSON.parse(JSON.stringify(itemFormBlankItem)))
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    const itemFormBlankItem = {
      product: {

      },
      qty: 1,
    }
    const orders = ref([])
    const current = new Date()

    const invoiceData = ref({
      number: '',
      customer: null,
      date: `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
      note: '',
      timbre: 1,
    })

    const products = ref([])
    axiosIns.get('/products/for-sales/').then(response => {
      products.value = response.data
    })

    return {
      invoiceData,
      products,
      itemFormBlankItem,
      orders,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
